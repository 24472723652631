import React from 'react'
export default function Home() {
  return (
    <div className='container'>
     
     {/* objectif specifique start */}

    <div className='container-sm'>
      <br />
    <h1>Objectif global</h1>
    <div class="grid text-center">
    <div class="row">
    {/* <!-- Colonne large à gauche --> */}
    <div class="col-md-7">
      <div class="content-large">
      <img src="./images/objectif.png" alt="" srcset="" />
      </div>
    </div>
    
    {/* <!-- Colonne étroite à droite avec deux colonnes empilées --> */}
    <div class="col-md-5">
      <div class="content-small">
       <p className='object-text'>
       It is very clear from these observations that violations of the internal policy provides rich insights into The Content of Early Formula
       </p>
      </div>
      <div class="content-small">
        <div className='graphe-object'>

        </div>
      </div>
    </div>
  </div>
 </div>
    </div>
    
    {/* objectif specifique end */}

{/* **********************************************************************************8 */}

{/* objectif global start */}

<div className="container-sm global mt-5">
  <br />
<h1>Objectif global</h1>

  <div  className='grid text-center'>
        <br />
        <div className="row">
          {/* First column */}
          <div className="col-md-8">

            <div className="content-left">
             {/* 1 */}
              <div className='global-contend'>
              <img src="./images/icon1.png" alt="" />
               <p className='global-text'>Without a doubt, Erwin Kelsey was right in saying that, the condition of the formal action seems to potentially </p>

              </div>
              <hr />
        {/* 2 */}
        <div className='global-contend'>
              <img src="./images/icon2.png" alt="" />
               <p className='global-text'>Without a doubt, Erwin Kelsey was right in saying that, the condition of the formal action seems to potentially </p>

              </div>
              <hr />
              {/* 3 */}
              <div className='global-contend'>
                
              <img src="./images/icon3.png" alt="" />
              
               <p className='global-text'>Without a doubt, Erwin Kelsey was right in saying that, the condition of the formal action seems to potentially </p>

              </div>
              <hr />

              {/* 4 */}

              <div className='global-contend'>
              <img src="./images/icon3.png" alt="" />
               <p className='global-text'>Without a doubt, Erwin Kelsey was right in saying that, the condition of the formal action seems to potentially </p>

              </div>
            </div>

          </div>



          {/* Second column */}
          <div className="col-md-4">
            <div className="content-right" style={{marginTop: '-30px'}}>
             <img src="./images/global.png" alt="" />
            </div>
          </div>
        </div>
  </div>
       
      </div>
  
{/* objectif global end */}

{/* ************************************************************************************ */}

{/* Groupe cible start */}
<div className='container-sm cible mt-5'>

  <div className=''>
   
   <div className='row'>

    <div className='col-md-8'>
    <h1>Groupe cible</h1>
<p className='cible-text'>
In a more general sense, the valuable information in its influence on the matter of the diverse sources of information represents opportunities for The Method of Dependent Interface In a more general sense, the valuable information in its influence on the matter of the diverse sources of information represents opportunities for The Method of Dependent InterfaceIn a more general sense, the valuable information in its influence on the matter of the diverse sources of information represents opportunities for The Method of Dependent Interface
</p>
    </div>

    <div className='col-md-4'>
    <img src="./images/cible.png" alt="" srcset="" />


    </div>

   </div>

  </div>

</div>
{/* Groupe cible end */}

{/* ****************************************************************************************    */}
   
   {/* zone d'intervention start  */}

   <div className='container-sm global'>
      <br />
    <h1>Zones d'interventions</h1>
    <div class="grid text-center">
    <div class="row">
    {/* <!-- Colonne large à gauche --> */}
    <div class="col-md-7">
      <div class="content-large">
      <img src="./images/zone.png" alt="" srcset="" />
      </div>
    </div>
    
    {/* <!-- Colonne étroite à droite avec deux colonnes empilées --> */}
    <div class="col-md-5">
      <div class="content-small">
       <p className='object-text'>
       It is very clear from these observations that violations of the internal policy provides rich insights into The Content of Early Formula
       </p>
      </div>
      <div class="content-small">
        <div className='graphe-object'>

        </div>
      </div>
    </div>
  </div>
 </div>
    </div>
    

      {/* zone d'intervention end  */}

{/* ******************************************************************************** */}

{/* quelaue statitstique start */}
 
 <div className='container-sm stac mt-5'>

 <div className='parent'>
  <div className='cadre'>
    <h2>Quelques statistiques</h2>
  </div>
</div>

<br />
<br />
<br />
<br />

{/* table */}

{/* table 1  start*/}
<div className='table-unfolding'>

<div className="table-container">
{/* <h3>title</h3> */}
<table >

<thead>
    <tr>
        <th>Total d'emploi créé</th>
        <th>Emploi AGR</th>
        <th>Emploi MER</th>
        <th>Homme employé</th>
        <th>Femme employée</th>
    </tr>
</thead>
<tbody>
    <tr>
        <td>8 000</td>
        <td>4 700</td>
        <td>3 300</td>
        <td>4 400</td>
        <td>3 600</td>
    </tr>
    <tr>
        <td>7 500</td>
        <td>5 500</td>
        <td>2 000</td>
        <td>4 300</td>
        <td>3 200</td>
    </tr>
    <tr>
        <td>5 000</td>
        <td>3 200</td>
        <td>1 800</td>
        <td>2 700</td>
        <td>2 300</td>
    </tr>
    <tr>
        <td>5 000</td>
        <td>3 500</td>
        <td>1 500</td>
        <td>2 800</td>
        <td>2 200</td>
    </tr>
</tbody>
</table>
</div>


<div className="table-container">
{/* <h3>title</h3> */}
<table >

<thead>
    <tr>
        <th>Total d'emploi créé</th>
        <th>Emploi AGR</th>
        <th>Emploi MER</th>
        <th>Homme employé</th>
        <th>Femme employée</th>
    </tr>
</thead>
<tbody>
    <tr>
        <td>8 000</td>
        <td>4 700</td>
        <td>3 300</td>
        <td>4 400</td>
        <td>3 600</td>
    </tr>
    <tr>
        <td>7 500</td>
        <td>5 500</td>
        <td>2 000</td>
        <td>4 300</td>
        <td>3 200</td>
    </tr>
    <tr>
        <td>5 000</td>
        <td>3 200</td>
        <td>1 800</td>
        <td>2 700</td>
        <td>2 300</td>
    </tr>
    <tr>
        <td>5 000</td>
        <td>3 500</td>
        <td>1 500</td>
        <td>2 800</td>
        <td>2 200</td>
    </tr>
</tbody>
</table>
</div>

<div className="table-container">
{/* <h3>title</h3> */}
<table >

<thead>
    <tr>
        <th>Total d'emploi créé</th>
        <th>Emploi AGR</th>
        <th>Emploi MER</th>
        <th>Homme employé</th>
        <th>Femme employée</th>
    </tr>
</thead>
<tbody>
    <tr>
        <td>8 000</td>
        <td>4 700</td>
        <td>3 300</td>
        <td>4 400</td>
        <td>3 600</td>
    </tr>
    <tr>
        <td>7 500</td>
        <td>5 500</td>
        <td>2 000</td>
        <td>4 300</td>
        <td>3 200</td>
    </tr>
    <tr>
        <td>5 000</td>
        <td>3 200</td>
        <td>1 800</td>
        <td>2 700</td>
        <td>2 300</td>
    </tr>
    <tr>
        <td>5 000</td>
        <td>3 500</td>
        <td>1 500</td>
        <td>2 800</td>
        <td>2 200</td>
    </tr>
</tbody>
</table>
</div>



<div>

</div>
   



</div>


</div>




{/* quelaue statitstique end */} 

{/* ***************************************************************************************** */}
   
    </div>
  )
}
 