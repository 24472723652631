import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleNavLinkClick = () => {
    setIsOpen(false); // Close the menu
  };

  return (
    <div className="Navbar">
      <div className='nav-logo'>
        <img src="./images/logo.png" alt="Logo" className='logo' />
      </div>

      <nav className={`nav-items ${isOpen && "open"}`}>
        <NavLink to='/' className={({ isActive }) => (isActive ? 'active' : '')} onClick={handleNavLinkClick}>
          Acceuil
        </NavLink>
        <NavLink to='/statistique' className={({ isActive }) => (isActive ? 'active' : '')} onClick={handleNavLinkClick}>
          Statistique
        </NavLink>
        <NavLink to='https://fier2.fidamali.org/fiches_dynamiques.php' return={false} onClick={handleNavLinkClick}>
          Connexion
        </NavLink>
      </nav>

      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
  );
}
