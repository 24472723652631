import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { PieChart } from '@mui/x-charts/PieChart';
import { mobileAndDesktopOS, valueFormatter } from '../webUsageStats.ts';

export default function Statistique() {
  const [radius, setRadius] = React.useState(50);
  const [itemNb, setItemNb] = React.useState(5);
  const [skipAnimation, setSkipAnimation] = React.useState(false);

  function ReactControlExample() {
    return (
      <MapContainer
        center={[51.505, -0.09]}
        zoom={6}
        scrollWheelZoom={false}
        style={{ height: '400px', width: '95%', margin: '0 auto', display: 'block' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    );
  }
  
  const handleItemNbChange = (event, newValue) => {
    if (typeof newValue !== 'number') return;
    setItemNb(newValue);
  };

  const handleRadius = (event, newValue) => {
    if (typeof newValue !== 'number') return;
    setRadius(newValue);
  };

  function toggleContent(element) {
    element.classList.toggle('active');
}
  return (
    <div>

      {/* Cartographie start */}
      <div className="container-statistique">
        <div className="container-sm">
          <br />
          <h1 style={{ marginBottom: '20px' }}>La Cartographie des indicateurs</h1>
          <ReactControlExample />
        </div>
      </div>
<br />
<br />
 {/* Cartographie end */}


{/* graphe start */}
      <div className="container-graphe">
      <h1 style={{ marginBottom: '20px' , marginLeft:'30px'}}>
    <br />
            Nombres d'entreprise qui utilise Ruche en fonction des pays
          </h1>
   <div className='row'>

  
    <div className='col-md-7'>
 
    <Box sx={{ width: '100%' }} style={{marginLeft:'30px'}}>
            <PieChart
              height={500}
              width={500}
              series={[
                {
                  data: mobileAndDesktopOS.slice(0, itemNb),
                  innerRadius: radius,
                  arcLabel: (params) => params.label ?? '',
                  arcLabelMinAngle: 20,
                  valueFormatter,
                },
              ]}
              skipAnimation={skipAnimation}
            />
          </Box>
    </div>

    <div className='col-md-5'>
     
     <div className='graph'>

     <div className='graph-text'>
     <h6>Comprendre le graphique </h6>
     </div>
 
     </div>

     
</div>



        </div>

        <div className='card-container'>


<div className='card'>

</div>


<div className='card'>

</div>


<div className='card'>

</div>


     </div>


      </div>
{/* graphe end */}


{/*  start */}

<div className="container-sm">
<div className='row'>

  
    <div className='col-md-4'>
    <div className="list-container">
    <div className="list-item" onclick="toggleContent(this)">
        <span className="title">Nombre d'installations de commercialisation, transformation ...</span>
        <span className="icon">&#10148;</span>
        <div className="content">Description détaillée de l'installation de commercialisation et transformation.</div>
    </div>
    <div className="list-item" onclick="toggleContent(this)">
        <span className="title">Nombres de prestataires de services financiers soutenus dans ...</span>
        <span className="icon">&#10148;</span>
        <div className="content">Détails sur les prestataires de services financiers soutenus.</div>
    </div>
    <div className="list-item" onclick="toggleContent(this)">
        <span className="title">Entreprises rurales appuyées signalant une hausse de leurs bé...</span>
        <span className="icon">&#10148;</span>
        <div className="content">Informations sur les entreprises rurales et leur croissance.</div>
    </div>
    <div className="list-item" onclick="toggleContent(this)">
        <span className="title">Bénéficiaires formés</span>
        <span className="icon">&#10148;</span>
        <div className="content">Nombre et types de bénéficiaires ayant suivi des formations.</div>
    </div>
    <div className="list-item" onclick="toggleContent(this)">
        <span className="title">Nombre de Personnes ayant accès à des services financiers da...</span>
        <span className="icon">&#10148;</span>
        <div className="content">Détails sur l'accès aux services financiers dans les régions ciblées.</div>
    </div>
    <div className="list-item" onclick="toggleContent(this)">
        <span className="title">Nombre de bénéficiaires par secteur d'activité</span>
        <span className="icon">&#10148;</span>
        <div className="content">Répartition des bénéficiaires par différents secteurs d'activité.</div>
    </div>
    <div className="list-item" onclick="toggleContent(this)">
        <span className="title">Montant financement Fier2 AGR</span>
        <span className="icon">&#10148;</span>
        <div className="content">Informations sur les financements alloués via Fier2 AGR.</div>
    </div>
    <div className="list-item" onclick="toggleContent(this)">
        <span className="title">Nombre de personnes dont la résilience s’est renforcée selon l...</span>
        <span className="icon">&#10148;</span>
        <div className="content">Description de l'impact des programmes de résilience.</div>
    </div>
</div>
    
    </div>



<div className='col-md-8'>

<div className='carde'>

<div className='card' style={{backgroundColor:'white'}}>

</div>


<div className='card' style={{backgroundColor:'white'}}>

</div>


<div className='card' style={{backgroundColor:'white'}}>

</div>


     </div>

<br />
<br />
<br />
<br />
<br />
<div className='container-statistique' >
  <br />
  <br />

</div>

</div>


</div>

</div>

    
    {/* end */}

    </div>
  );
}
