import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './Home/Home';
import Navbar from './Navbar/Navbar';
import Statistique from './Statistique/Statistique';
import Footer from './Footer/Footer';
function App() {
  return (
    
   <Router>
    <Routes>
      <Route path='/' element ={
        <>
         <Navbar/>
         <Home />
         <Footer />
        </>
        
      }/>
     <Route path='/statistique' element ={
        <>
         <Navbar/>
         <Statistique />
         <Footer />
        </>
        
      }/>
    </Routes>
   </Router>
  );
}

export default App;
