import React from 'react'

export default function Footer() {
  return (
    <div>
{/* footer start   */}
   
<footer>

{/* Groupe cible start */}
<div className='container-sm mt-5'>

   
   <div className='row'>

    <div className='col-12 col-md-8'>

    <img src="./images/logo.png" alt="" srcset="" className='footer-logo' />
     
     <br />
     <br />

     <div className='phone'>
      <img src="./images/Phone.png" alt=""  />
      <h4>+233 x x x x </h4>
     </div>
      <br />
      <br />
      <br />
      <br />
     <div className='phone'>
      <img src="./images/Mail.png" alt="" />
      <h4>ruche@gmail.com </h4>
     </div>

    </div>

    <div className='col-12 col-md-4 mob'>
        <br />
        <br />
        <div className='parent'>
        <div className='list'>
        <h5>Liens utiles</h5>
    </div>
        </div>
    
     
     <div className='scrollable-list'>

     <ul className='contain'>
        <li className='item'>Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état</li>
        <li className='item'>Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état</li>
        <li className='item'>Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état</li>
        <li className='item'>Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état</li>
        <li className='item'>Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état</li>
        <li className='item'>Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état</li>
        <li className='item'>Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état</li>
        <li className='item'>Nombre d'installations de commercialisation, transformation et stockage construites ou remises en état</li>

    </ul>

    

     </div>
  
   
    </div>

   </div>


</div>
{/* Groupe cible end */}


</footer>

{/* footer end */}
   
    </div>
  )
}
